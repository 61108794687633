const generate = function (length) {
  return Array(length)
    .fill('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz!@#$%^&*_+-=()[]{}?')
    .map((x) => { return x[Math.floor(Math.random() * x.length)] })
    .join('')
}

const Password = {
  generate
}

export default Password
