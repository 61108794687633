<template>
  <div>
    <portal to="page-top-title">
      <span v-if="isProfile">Edit Profile</span>
      <span v-else>Edit User #{{ id }}</span>
    </portal>
    <portal to="page-top-right">
      <btn :route="isProfile ? { name: 'profile' } : { name: 'users-single', params: { id: id } }" icon="fa-eye" text>
        <span v-if="isProfile">Back to Profile</span>
        <span v-else>Back to User</span>
      </btn>
    </portal>

    <box-form action="edit" data-prop="formData" :redirect="isProfile ? 'profile' : 'users-list'" :url="this.dataUrl" :loaded="loaded" loader>
      <template slot="content">
        <row>
          <column-input>
            <form-group-input name="name" :model="formData.name" :min="2" :max="250" required/>
          </column-input>
          <column-input>
            <div class="form-group">
              <label for="email">Email</label>
              <input class="form-control" id="email" :value="data.email" autocomplete="username" disabled/>
            </div>
          </column-input>
          <column-input>
            <div class="form-group">
              <label for="role">Role</label>
              <input class="form-control" id="role" :value="data.role" disabled/>
            </div>
          </column-input>
        </row>
        <row>
          <column-input v-if="data.selfEdit">
            <form-group-input name="password" title="New Password" :model="formData.password" :min="12" :max="250" auto="new-password">
              <btn slot="help" type="button" color="warning" size="xs" @click.native.prevent="generatePswd" flat text>Generate</btn>
            </form-group-input>
          </column-input>
          <column-input :md="2">
            <form-group-check name="active" :model="formData.active"/>
          </column-input>
        </row>
        <row v-if="data.selfEdit">
          <column-input>
            <form-group-input title="Current Password" name="password_current" type="password" auto="current-password" :model="formData.password_current" required>
              <p slot="help">* Current Password is required to change the data.</p>
            </form-group-input>
          </column-input>
        </row>
      </template>
    </box-form>
  </div>
</template>

<script>
import BoxForm from '@/views/components/data/BoxForm'
import FormGroupCheck from '@/views/components/form/FormGroupCheck'
import FormGroupInput from '@/views/components/form/FormGroupInput'
import Btn from '@/views/components/simple/Btn'
import Form from '@/modules/utils/Form'
import Password from '@/modules/utils/Password'
import { mapGetters } from 'vuex'

export default {
  name: 'UsersEdit',
  metaInfo () {
    return { title: this.isProfile ? 'Profile | Edit' : `User #${this.id} | Edit` }
  },
  components: {
    BoxForm,
    Btn,
    FormGroupCheck,
    FormGroupInput
  },
  computed: {
    ...mapGetters({
      userAuth: 'auth/user',
      roles: 'auth/roles'
    }),
    isProfile () { return this.$route.name === 'profile-edit' },
    id () { return this.isProfile ? this.userAuth.id : this.$route.params.id },
    dataUrl () { return this.isProfile ? '/profile' : `/users/${this.id}` }
  },
  data () {
    return {
      loaded: false,
      data: {
        email: '',
        role: '',
        selfEdit: false
      },
      formData: {
        name: Form.types.input(),
        password: Form.types.input(),
        password_current: Form.types.input(),
        active: Form.types.boolean(true)
      }
    }
  },
  created () {
    this.loadEntity()
  },
  methods: {
    loadEntity () {
      this.$http
        .get(this.dataUrl)
        .then((res) => {
          const { data: { data } } = res

          this.data.email = data.email
          this.data.role = data.roles[0]?.name || 'Unknown'
          this.data.selfEdit = data.id === this.userAuth.id

          this.formData.name.value = data.name
          this.formData.active.value = data.active

          this.loaded = true
        })
    },
    generatePswd () {
      this.formData.password.value = Password.generate(20)
    }
  }
}
</script>
